<template>
    <div>
        <router-link :to="{ name: 'Home' }" class="homeLink">
            Home
        </router-link>
    </div>
    <div>
        <router-link :to="{ name: 'About' }">
            {{ $store.state.general.aboutName }}
        </router-link>
    </div>
    <template v-for="link, i in $store.state.general.links" :key="link.link+':'+i">
        <div>
            <a :href="link.url">
                {{ link.text }}
            </a>
        </div>
    </template>
</template>

<style scoped lang="scss">
a {
    color: white;
}
div {
        margin: 20px auto;
      }

@media screen and (min-width:$breakPoint) {
    .homeLink {
        display: none;
    }
    div {
        margin: 0px auto;
      }
    
}
</style>