import { createStore } from 'vuex'
import general from "./general"
import about from "./about"
import expos from "./expos"
import axios from "axios"
import API_BASE from "./apiBase"

function generateStore(data) {
  return createStore({
    state: {
      aboutURL: data.aboutURL,
      exposURL: data.exposURL,
      siteName: data.siteName,
      aboutColor: data.aboutColor
    },
    mutations: {
    },
    actions: {
    },
    modules: {
      general,
      about,
      expos
    }
  })
}

async function loadEssentialInfoForStore() {
  return new Promise((resolve, reject) => {
    axios
      .get(API_BASE + "?type=essential")
      .then(response => {
        console.log(response)
        if ("error" in response.data) {
          reject(response.data.error)
        }
        else {
          resolve(generateStore(response.data.data))
        }
      })

  })

}

export default loadEssentialInfoForStore
