<template>
  <img
    :data-srcset="generateSources(img.imgs)"
    :width="img.width"
    :height="img.height"
    :src="generateSVGCode"
    class="lazy"
  />
</template>

<script>
export default {
  props: ["img"],
  mounted() {
    this.$nextTick(function () {
      this.$root.lazyload.update();
    });
  },
  methods: {
    generateSources(sizes) {
      var s = "";
      sizes.forEach((element) => {
        if (s != "") s += ", ";
        s += "" + element.url + " " + element.w + "w";
      });
      return s;
    },
  },
  computed: {
    generateSVGCode() {
      return (
        "data:image/svg+xml;utf8,<svg width='" +
        this.img.width +
        "' height='" +
        this.img.height +
        "' viewBox='0 0 " +
        this.img.width +
        " " +
        this.img.height +
        "' version='1.1' style='background-color:lightgrey' xmlns='http://www.w3.org/2000/svg'></svg>"
      );
    },
  },
};
</script>
