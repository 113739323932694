import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Expo from '../views/Expo.vue'

var homePosition = 0;
function _createRouter(store) {
  const routes = [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/' + store.state.aboutURL,
      name: 'About',
      component: About
    },
    {
      path: '/' + store.state.exposURL + "/:slug",
      name: 'Expo',
      component: Expo
    }
  ]

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (to.name == "Home") {
        document.querySelector("#mainContainer").scrollTo(0, homePosition)
      }
      else {
        document.querySelector("#mainContainer").scrollTo(0, 0)
      }

    },
  })

  function matchChild(name, to) {
    return !!to.matched.filter(e => e.name == name).length
  }
  router.beforeEach(async (to, from, next) => {
    var notfound = false;
    if (from.name == "Home") {
      console.log("yes")
      homePosition = document.querySelector("#mainContainer").scrollTop;
    }

    if (!store.state.general.loaded) {
      await store.dispatch("general/load")
    }
    if (!store.state.about.loaded) {
      await store.dispatch("about/load")
    }

    if ((matchChild("Home", to) || matchChild("Expo", to)) && !store.state.expos.loaded) {
      await store.dispatch("expos/loadExpos")
    }
    if (matchChild("Expo", to)) {
      await store.dispatch("expos/loadExpo", to.params.slug)
    }
    // if (matchChild("InfoPage", to)) {
    //   try {
    //     await store.dispatch("infopage/loadPage", to.params)
    //   }
    //   catch (e) {
    //     notfound = true;
    //   }
    // }
    if (notfound) {
      next({ name: "PathNotFound" })
    }
    else {
      next()
    }
  })
  return router;
}


export default _createRouter
