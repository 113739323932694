<template>
  <div class="logoContainer">
    <router-link :to="{ name: 'Home' }" class="desktopLink">
      <image-el v-if="$store.state.general.loaded" :img="$store.state.general.logo" sizes="100px" />
    </router-link>
    <image-el v-if="$store.state.general.loaded" :img="$store.state.general.logo" class="mobileLink" @click="menuVisible = true" sizes="100px" />
  </div>
  <div :class="'linkPageContainer ' + (menuVisible ? 'visible' : '')" @click="menuVisible = false">
    <div class="mobileLinks">
      <div class="vAligner">
        <links />
      </div>
    </div>
  </div>
  <div class="desktopLinks">
    <div class="vAligner">
      <links />
    </div>
  </div>
</template>

<script>
import Links from "./Links.vue"
export default {
  data() {
    return {
      menuVisible: false
    }
  },
  methods: {
    makeVisible() {
      this.menuVisible = true
      this.$root.disableScroll()
    },
    makeInvisble() {
      this.menuVisible = false

      this.$root.enableScroll()
    }
  },
  name: 'Home',
  components: {
    Links
  }
}
</script>
<style lang="scss" scoped>
.logoContainer,
.desktopLinks {
  position: fixed;
  z-index: 20;

  line-height: 1em;

  .homeLink {
    display: none;
  }

  a {

    color: white !important;
  }
}

.logoContainer {
  top: 50%;
  left: $desktopPadding;
  transform: translateY(-50%);
  width: 100px;
  height: 100px;

  img {
    width: 100%;
    height: auto;
  }
}

.desktopLinks {
  top: 50%;
  right: $desktopPadding;
  transform: translateY(-50%);
  text-align: right;
  mix-blend-mode: difference;

}

.mobileLink,
.linkPageContainer {
  display: none;
}

@media screen and (max-width: $breakPoint2) {
  .logoContainer {
    left: $mobilePadding;
    width: 80px;
    height: 80px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .desktopLinks {
    right: $mobilePadding;
  }
}

@media screen and (max-width: $breakPoint) {
  .mobileLink {
    display: inline-block;
  }

  .desktopLink {
    display: none;
  }

  .desktopLinks {
    display: none;
  }

  .logoContainer {
    top: $mobilePadding;
    left: $mobilePadding;
    transform: unset;
    width: 40px;
    height: 40px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .linkPageContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    // background-color: #505050aa;
    z-index: 15;

    &.visible {
      display: block;
    }
  }

  .mobileLinks {
    border: $mobilePadding solid black;
    color: white;
    background-color: #838383;
    width: calc(100% - 10*14px);
    box-sizing: border-box;
    padding: $mobilePadding;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    text-align: center;
    height: calc(60vh - 28px);

    .vAligner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);


    }

  }
}
</style>
