<template>
    <router-link v-if="isInternal" :to="internalLink">
        <slot />
    </router-link>
    <a v-else :href="externalLink">
        <slot />
    </a>
</template>

<script>
export default {
    props: ["isInternal", "internalLink", "externalLink"]
}
</script>