<template>
    <div class="mobile">
        <div>
            <router-link :to="previous">Previous</router-link>
        </div>
        <div class="home">
            <router-link :to="{ name: 'Home' }">Home</router-link>
        </div>
        <div>
            <router-link :to="next">Next</router-link>
        </div>

    </div>
    <div class="desktop">
        <template v-for="expo, i in pastExpos" :key="expo.id+i">
            <router-link :to="{ name: 'Expo', params: { slug: expo.slug } }" v-html="expo.name_short" class="expoLink" />
            <!-- {{ i != pastExpos.length - 1 ? ", " : "" }} -->
        </template>
    </div>
</template>

<script>
import { mapState } from "vuex"
export default {
    props: ["expo"],
    computed: {
        ...mapState("expos", ["expos"]),
        previous() {
            var prevExpo = this.pastExpos[(this.currIndex - 1 + this.pastExpos.length) % this.pastExpos.length]
            return { name: 'Expo', params: { slug: prevExpo.slug } }
        },
        next() {
            var nextExpo = this.pastExpos[(this.currIndex + 1 + this.pastExpos.length) % this.pastExpos.length]
            return { name: 'Expo', params: { slug: nextExpo.slug } }
        },
        pastExpos() {
            return this.expos.filter(e => e.a_venir == false)
        },
        currIndex() {
            var currIndex = -1;
            this.pastExpos.forEach((expo, i) => {
                if (expo.id == this.expo.id) {
                    currIndex = i;
                }
            })
            return currIndex;
        }
    },
    methods: {
    }
}
</script>

<style scoped lang="scss">
a {
    color: black;
}

.mobile {
    display: flex;
    font-size: 20px;

    div {
        flex-basis: 83px;

        &:last-of-type {
            text-align: right;
        }
    }

    .home {
        flex-grow: 3;
        align-self: center;
        text-align: center;
    }
}

.desktop {
    display: none;
    text-align: center;

    .expoLink {
        margin: 0px 10px;
        display: inline-block;
    }
}

@media screen and (min-width : $breakPoint) {
    .desktop {
        display: block;
    }

    .mobile {
        display: none;
    }
}
</style>