import API_BASE from "./apiBase"

import axios from "axios"
export default {
    namespaced: true,
    state: () => ({
        loaded: false,
        expos: [],
        currentExpo: null
    }),
    mutations: {
        UPDATE_EXPO(state, payload) {
            state.currentExpo = payload
        },
        UPDATE_EXPOS(state, payload) {
            console.log(payload)
            state.expos = payload
        }
    },
    actions: {
        async loadExpos({ commit, state }) {
            return new Promise((resolve, reject) => {
                if (state.loaded) {
                    resolve()
                }
                else {
                    axios
                        .get(API_BASE + "?type=expos")
                        .then(response => {
                            if ("error" in response.data) {
                                reject(response.data.error)
                            }
                            else {
                                commit("UPDATE_EXPOS", response.data.data.expos)
                                resolve()
                            }
                        })
                }
            })
        },
        async loadExpo({ commit, state }, name) {
            return new Promise((resolve, reject) => {
                axios
                    .get(API_BASE + "?type=expo&slug=" + name)
                    .then(response => {
                        if ("error" in response.data) {
                            reject(response.data.error)
                        }
                        else {
                            commit("UPDATE_EXPO", response.data.data.expo)
                            resolve()
                        }
                    })


            })

        },
    },
    getters: {
    }
}