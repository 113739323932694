<template>
    <div class="fakeWhite">
        <div :class="'expo ' + (avenir ? 'avenir' : '')" :style="generateBoxStyle">
            <div v-if="avenir" class="upcoming">
                <div class="upcomingInside" :style="upcomingStyle">
                    UPCOMING
                </div>
            </div>
            <div class="text top" :style="{ color: '#' + expo.couleur }">{{ expo.date }}<br><span class="upcomingText">Upcoming</span></div>
            <div class="imageContainer">
                <image-el class="i" :img="expo.intro_image" v-if="image" sizes="(max-width: 768px) 100vw, (max-width: 1400px) 80vw, 900px" />
                <div class="nameOverlay">
                    <image-el :img="expo.name_image" sizes="(max-width: 768px) 80vw, (max-width: 1400px) 70vw, 600px" v-if="expo.name_image" />
                </div>

            </div>
            <div class="text bottom" :style="{ color: '#' + expo.couleur }">{{ expo.title }}</div>
        </div>
    </div>

</template>
<script>
export default {
    props: ["expo", "avenir", "image"],
    computed: {
        generateBoxStyle() {
            var style = {
                borderColor: this.expo.couleur,
                backgroundColor: this.expo.couleur_2,
                color: this.expo.couleur
            }
            return style
        },
        upcomingStyle() {
            var style = {
                borderColor: this.expo.couleur,
                backgroundColor: this.expo.couleur_2,
                color: this.expo.couleur
            }
            return style
        }
    }
}
</script>
<style lang="scss" scoped>
.fakeWhite {
    background-color: white;
}

.expo {
    background-color: white;
    border-width: $desktopPadding;
    border-style: solid;
    height: 60vh;
    text-align: center;
    position: relative;

    &:hover img.i {
        // opacity: 1;
    }
}

a {
    color: black;
    text-decoration: none;
}

.text {
    padding: 0px 10px;
    font-weight: bold;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    width: 100%;
    box-sizing: border-box;

    &.top {
        top: $desktopPadding;
    }

    &.bottom {
        bottom: $desktopPadding;
    }
}

.upcoming {
    position: absolute;
    top: 0px;
    left: 0px;
    transform: translate(-50%, -50%) rotate(-15deg);
    border-radius: 100px;
    width: 150px;
    height: 150px;
    color: black;
    font-weight: bold;
    line-height: 150px;
    z-index: 10;
    background-color: white;

    .upcomingInside {
        border-style: dashed;
        border-width: 4px;
        box-sizing: border-box;
        border-radius: 100px;
        width: 150px;
        height: 150px;
    }
}

.imageContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;

    img.i {
        width: 100%;
        height: 100%;
        opacity: 0;
        object-fit: cover;
    }

    .nameOverlay {
        width: 80%;
        height: calc(100% - 80px);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }
}

.upcomingText {
    display: none;
}


@media screen and (max-width: $breakPoint) {
    .expo {
        border-width: $mobilePadding;
    }

    .text {
        &.top {
            top: $mobilePadding;
        }

        &.bottom {
            bottom: $mobilePadding;
        }
    }

    .upcoming {
        display: none;
    }

    .avenir .upcomingText {
        display: inline-block;
    }
}

@media (hover: hover) {

    /* when hover is supported */
    .expo:hover img.i {
        opacity: 1;
    }
    .expo.avenir:hover img.i {
        opacity: 0;
    }

}
</style>