<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>
  <Nav />
  <div id="mainContainer" :style="{ backgroundColor: $route.name == 'About' ? ('#' + $store.state.aboutColor) : 'white' }">
    <router-view />
  </div>
</template>
<script>
import LazyLoad from "vanilla-lazyload";
import Nav from "@/components/Nav"
import PerfectScrollbar from 'perfect-scrollbar';
import { useMeta } from 'vue-meta'
export default {
  setup() {

  },
  data() {
    return {
      lazyload: null,
      ps: null
    };
  },
  components: {
    Nav
  },
  mounted() {
    this.lazyload = new LazyLoad();
    window.addEventListener("resize", this.resize)
    useMeta({
      title: this.$store.state.siteName,
      htmlAttrs: { lang: 'en', amp: true },
    })
    this.enableScroll()
  },
  methods: {
    resize() {
      this.ps.update()
    },
    disableScroll() {
      this.ps.destroy();
      this.ps = null;
    },
    enableScroll() {
      this.ps = new PerfectScrollbar("#mainContainer");
    }
  },
  computed: {
    route() {
      return this.$route;
    }
  },
  watch: {
    route: {
      handler() {
        this.$nextTick(() => {
          this.ps.update()
        })
      }
    }
  },
};
</script>
<style lang="scss">
@font-face {
  font-family: "SDWalla";
  src: url('./assets/fonts/WOFF2/SDWalla-Black.woff2') format('woff2'),
    url('./assets/fonts/TTF/SDWalla-Black.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: "SDWalla";
  src: url('./assets/fonts/WOFF2/SDWalla-BlackItalic.woff2') format('woff2'),
    url('./assets/fonts/TTF/SDWalla-BlackItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}


html,
body {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
  background-color: white;
}

a {
  text-decoration: none;
}

#app {
  font-family: "SDWalla", Arial, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  // text-align: center;
  width: 100%;
  height: 100%;
  background-color: white;
}

#mainContainer {
  width: 100%;
  height: 100%;
  background-color: white;
  transition: background-color 400ms;
  position: absolute;
  overflow: hidden;

  &.about {
    background-color: red;
  }
}

em {
  font-style: normal!important;
}

p {

  &:first-of-type {
    margin-top: 0px;
  }

  &:last-of-type {
    margin-bottom: 0px;
  }
}


@media screen and (max-width: $breakPoint) {
  #app {
    font-size: 12px;
  }
}
</style>
