import { createApp } from 'vue'
import App from './App.vue'
import createRouter from './router'
import createStore from './store'
import mixin from "./mixins/mixin.js"
import { createMetaManager } from 'vue-meta'

createStore().then((store) => {
    createApp(App)
    .mixin(mixin)
    .use(store)
    .use(createRouter(store))
    .use(createMetaManager())
    .mount('#app')
})
