<template>
    <div class="intro">
        <div class="introImage">
            <image-el :img="expo.intro_image"
                      :key="'bg' + expo.id"
                      sizes="(max-width: 768px) 150vw, 100vw" />

        </div>
        <div class="expoBoxContainer">
            <expo-box :expo="expo"
                      :avenir="false"
                      :image="false"
                      :key="expo.id" />
        </div>
    </div>
    <div class="contentContainer"
         :style="generateBG">
        <template v-for="item in expo.content"
                  :key="item.id">
            <div v-if="item.type == 'image'"
                 class="content image">
                <div class="imageContainer">
                    <image-el class="nameOverlay"
                              :img="item.image"
                              :key="expo.id + ' ' + item.id"
                              sizes="(max-width: 768px) 100vw, (max-width: 1400px) 80vw, 1200px" />
                </div>
                <div v-html="item.legend"
                     class="legend"
                     v-if="item.legend != ''" />
            </div>
            <div v-else-if="item.type == 'expo_text'"
                 class="expoTextContainer content"
                 :style="generateBoxStyle">
                <div v-html="item.text" />
                <div v-html="item.legend"
                     class="legend"
                     v-if="item.legend != ''"
                     :style="generateColor" />
            </div>
        </template>
    </div>
    <div class="extraTextContainer">
        <div v-html="expo.bio"
             class="actualText" />
        <div class="bottomText">
            <bottom-text :expo="expo" />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex"
import { useMeta } from 'vue-meta'
import ExpoBox from "@/components/ExpoBox"
import BottomText from "@/components/BottomText"
export default {
    components: {
        ExpoBox,
        BottomText
    },
    computed: {
        ...mapState("expos", {
            expo: "currentExpo"
        }),
        generateBoxStyle() {
            var style = {
                borderColor: this.expo.couleur,
                color: this.expo.couleur
            }
            return style
        },
        generateBG() {
            var style = {
                borderColor: this.expo.couleur,
                backgroundColor: this.expo.couleur_2,
                color: this.expo.couleur
            }
            return style
        },
        generateColor() {
            return {
                color: this.expo.couleur + "88"
            }
        }
    },
    mounted() {
        console.log(this.expo.intro_image)
        useMeta({
            title: this.$store.state.siteName + " - " + this.expo.title,
            description: this.expo.seo_description,
            og: {
                image: this.expo.intro_image.imgs[3].url
            },
        })
    },
}
</script>
<style lang="scss" scoped>
.intro {
    width: 100%;
    height: 100%;

    position: relative;

    .introImage {
        width: 100%;
        height: 100%;

        position: relative;

        img {
            position: absolute;
            top: 0px;
            left: 0px;
            object-fit: cover;
            width: 100%;
            height: 100%;
            z-index: 0;
        }
    }

    .expoBoxContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 800px;
        max-width: 60%;
    }
}

.contentContainer {
    border-style: solid;
    border-width: $desktopPadding 0px;
}

.image .legend {
    text-align: center;
    padding-top: 10px;
    height: 25px;
    box-sizing: border-box;
}

.expoTextContainer {
    padding: 100px;
    background-color: white !important;
    font-size: 25px;
    line-height: 40px;
    border-width: $desktopPadding;
    border-style: solid;

    .legend {
        text-align: left;
        margin-top: 2em;
    }
}

.expo_text {}

.content {
    max-width: 1200px;
    // min-width: 600px;
    width: 70%;
    margin: 100px auto;
    box-sizing: border-box;
}

.imageContainer {
    width: 100%;

    img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 90vh;
        margin: 0px auto;
        display: block;
    }
}


.extraTextContainer {
    height: 100%;
    position: relative;
    width: 100%;
    line-height: 1em;

    .actualText {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: $centerColumnWidth;
    }

    .bottomText {
        width: 100%;
        position: absolute;
        left: 0%;
        bottom: 13px;
        padding: 0px 13px;
        box-sizing: border-box;
    }
}

@media screen and (max-width: $breakPoint2) {
    .expoTextContainer {
        padding: $desktopPadding;
    }
}

@media screen and (max-width: $breakPoint) {
    .intro .expoBoxContainer {
        padding: $mobilePadding;
        box-sizing: border-box;
        max-width: unset;
        width: calc(100% - 4*14px)
    }

    .contentContainer {
        border-width: $mobilePadding 0px;
    }

    .expoTextContainer {
        font-size: 15px;
        line-height: 20.5px;
        padding: $mobilePadding;
        border-width: $mobilePadding;
        border-style: solid;
    }

    .content {
        margin: 45px auto;
        width: 100%;

        &.image {
            padding: $mobilePadding;
        }
    }

    .extraTextContainer {
        padding: $mobilePadding;
        min-height: 100%;
        height: unset;
        padding-bottom: 100px;
        box-sizing: border-box;
    }

    .actualText {
        width: calc(100% - 30px);
    }
}
</style>