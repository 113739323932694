<template>
    <link-handler class="expoLink" :internalLink="{ name: 'Expo', params: { slug: expo.slug } }" :externalLink="expo.extLink" :is-internal="!expo.a_venir">
        <expo-box :expo="expo" :avenir="expo.a_venir" :image="true" />
    </link-handler>
</template>
<script>
import LinkHandler from "./LinkHandler.vue"
import ExpoBox from "./ExpoBox.vue"
export default {
    props: ["expo"],
    components: {
        LinkHandler,
        ExpoBox
    },
    computed: {

    }
}
</script>
<style lang="scss">
.expoLink {
    margin-bottom: 3*$desktopPadding;
    display: block;

    &:last-of-type {
        margin-bottom: 0*$desktopPadding;
    }
}


@media screen and (max-width: $breakPoint) {
    .expoLink {
        margin-bottom: 3*$mobilePadding;
    }
}
</style>