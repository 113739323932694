<template>
  <div class="listContainer">
    <template v-for="expo in $store.state.expos.expos" :key="expo.id">
      <expo-link :expo="expo" />
    </template>
  </div>
</template>

<script>
import ExpoLink from "@/components/ExpoLink"
import { useMeta } from 'vue-meta'
export default {
  name: 'Home',
  components: {
    ExpoLink
  },
  mounted() {
    useMeta({
      title: this.$store.state.siteName,
      og: {
        image: this.$store.state.general.og_image
      },
      description: this.$store.state.general.seo_description
    })
  }
}
</script>
<style lang="scss" scoped>
.listContainer {
  width: 800px;
  max-width: 60%;
  margin: 0px auto;
  padding: 15vh 0px;
  box-sizing: border-box;
}


@media screen and (max-width: $breakPoint) {
  .listContainer {
    padding: 15vh $mobilePadding*3;
    width: 100%;
    max-width: unset;
  }
}
</style>
