import API_BASE from "./apiBase"

import axios from "axios"
export default {
    namespaced: true,
    state: () => ({
        loaded : false,
        logo: null,
        aboutName: null,
        links: [],
        seo_description: null,
        og_image: null
    }),
    mutations: {
        UPDATE_GENERAL(state, payload) {
            for (const key of Object.keys(payload)) {
                state[key] = payload[key]
            }
            state.loaded = true
        }
    },
    actions: {
        async load({ commit, state }) {
            return new Promise((resolve, reject) => {
                if (state.loaded) {
                    resolve()
                }
                else {
                    axios
                        .get(API_BASE + "?type=general")
                        .then(response => {
                            if ("error" in response.data) {
                                reject(response.data.error)
                            }
                            else {
                                commit("UPDATE_GENERAL", response.data.data)
                                resolve()
                            }
                        })
                }

            })

        },
    },
    getters: {
    }
}