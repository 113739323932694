<template>
    <div class="container">
        <div class="mainText aboutStyle" v-html="$store.state.about.center_text" />
        <div class="thanksText aboutStyle" v-html="$store.state.about.bottom_text" />
    </div>
</template>

<script>
import { useMeta } from 'vue-meta'
export default {
    mounted() {
        useMeta({
            title: this.$store.state.siteName + " - " + this.$store.state.general.aboutName
        })
    }
}
</script>
<style lang="scss">
.container {
    padding: $desktopPadding;
    box-sizing: border-box;

    a {
        color: white;
        text-decoration: none;
    }
}

.mainText {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    max-width: 60%;
    border: 25px solid white;
    box-sizing: border-box;
    padding: $desktopPadding*3;
    background-color: #838383;
    color: black;
    text-align: center;


}

.thanksText {
    position: absolute;
    left: 50%;
    bottom: $desktopPadding;
    transform: translateX(-50%);
    width: 100%;
    max-width: 800px;
    text-align: center;
    color: #838383;

}





@media screen and (max-width:$breakPoint) {
    .mainText {
        padding: $mobilePadding;
        border-width: $mobilePadding;
        max-width: unset;
        width: calc(100% - 6*14px);
    }

    .thanksText {
        bottom: $mobilePadding;
    }
}
</style>

